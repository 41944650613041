// SEOScraping
// SEO Scraping

import React from "react";
import colors from "../../constants/colors";
import AboutUsGrid from "./about-us-grid";
import house6 from "../../assets/house-6.jpg";
import QuoteForm from "../home-page/quote-form";
//@ts-ignore
import { useMediaQuery } from "react-responsive";
import TopSection from "./top-section";
const TITLE = `Our Team`;
const TEXT = `Preferred Title & Escrow, Inc. is a corporation organized and existing under the laws of the State of Maryland. The principals formed a title company in March 1990, which was named Phoenix Settlement Services. Unfortunately this name was similar to the name of a real estate investment company in the area. To avoid confusion, the name was changed, and in October of 1992, Preferred Title & Escrow, Inc. became a corporation. The principals of the company have not changed since the formation in 1990.`;

export default function OurTeam(props: { isMobile: boolean }) {
  const isMedium = useMediaQuery({ query: `(max-width: 1150px)` });

  return (
    <div style={props.isMobile ? {} : {}}>
      <TopSection
        image={house6}
        isMobile={props.isMobile}
        title={TITLE}
        text={TEXT}
        altText={TEXT}
      />

      <div style={props.isMobile ? {} : { paddingLeft: 40, paddingRight: 40 }}>
        <div
          style={{
            paddingLeft: props.isMobile ? 0 : 50,
            paddingRight: props.isMobile ? 0 : 50,
            marginTop: props.isMobile ? 20 : -120,
          }}
        >
          <AboutUsGrid isMobile={props.isMobile} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: -30,
          }}
        >
          <QuoteForm isMobile={props.isMobile} />
        </div>
      </div>
    </div>
  );
}
