import colors from "../constants/colors";
import React from "react";

export function CTAButton({
  isMobile,
  text,
}: {
  isMobile: boolean;
  text: string;
}) {
  return (
    <div
      style={{
        width: isMobile ? 200 : 240,
        height: isMobile ? 50 : 60,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderColor: colors.yellow,
        borderWidth: 2,
        borderRadius: 8,
        backgroundColor: colors.yellow,
        borderStyle: "solid",
      }}
    >
      <p
        style={{
          padding: 0,
          margin: 0,
          fontWeight: "bold",
          letterSpacing: 1.02,
          fontSize: isMobile ? 18 : 22,
          color: colors.red,
        }}
      >
        {text}
      </p>
    </div>
  );
}
