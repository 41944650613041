import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaGavel } from "react-icons/fa";
import { MdAccountCircle, MdPersonPin } from "react-icons/md";
import colors from "../../constants/colors";

export default function AboutUsGrid(props: { isMobile: boolean }) {
  const renderInfoSquare = (
    icon: any,
    title: string,
    topText: string[],
    text: string
  ) => {
    return (
      <Col
        sm={12}
        md={12}
        lg={12}
        style={{ paddingBottom: 12, paddingTop: 12 }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            boxShadow: "2px 2px 12px 2px rgba(0,0,0,0.3)",
            padding: 20,

            margin: 12,
            flex: 1,
            height: "100%",
          }}
        >
          {/* {icon} */}

          <p
            style={{
              marginBottom: 8,
              color: colors.red,
              fontWeight: 900,
              fontSize: 24,
            }}
          >
            {title}
          </p>
          <p style={{ fontStyle: "italic" }}>
            {topText.map((text) => (
              <div>
                <span>{text}</span>
                <br />
              </div>
            ))}
          </p>

          <p>{text}</p>
        </div>
      </Col>
    );
  };

  return (
    <Row style={{}}>
      {renderInfoSquare(
        <MdAccountCircle style={{ fontSize: 55, color: colors.red }} />,
        "Margaret E. Law",
        [
          `President, Preferred Title & Escrow, Inc.`,
          `BA University of Maryland, College Park`,
          `Certified Paralegal, University of Maryland, University College, 1976`,
        ],

        "Ms. Law has been a manager/owner in the real estate industry since 1972. She started by opening a title company division for an attorney which was a great success and has been working in real estate ever since. She has handled all aspects of the settlement business from typing documents to solving complex title problems. As Pre Settlement Manager and owner of Preferred Title & Escrow, Inc., Ms. Law takes a very hands on approach and is very active in resolving title problems and in ensuring that clients get what they need in a timely manner."
      )}

      {renderInfoSquare(
        <MdPersonPin style={{ fontSize: 55, color: colors.red }} />,
        "Kathi Redford",
        [
          `Vice President, Preferred Title & Escrow, Inc.`,
          `BA University of Maryland 1987`,
        ],

        "Ms. Redford has been working in the real estate field since 1983. She worked her way through college at a real estate settlement attorney’s office and was manager of the office before she graduated. She has handled all aspects of real estate settlements and specializes in the closing of transactions and follow-up work. As post closing manager she reviews all transactions, which close at Preferred Title to ensure that everything is recorded properly and that the title insurance is issued as soon as possible."
      )}

      {renderInfoSquare(
        <FaGavel style={{ fontSize: 55, color: colors.red }} />,
        "Robert H. Law, Esquire",
        [
          `BA University of West Virginia, 1956`,
          `JD American University, 1961`,
          `Admitted to Maryland Bar, June 1962`,
        ],

        "Mr. Law is an attorney who has been practicing in Maryland since 1962. He is the Senior Member of his Law Firm. He has handled all aspects of the law including real estate contract law. He has worked both with residential and commercial real estate and has handled real estate settlements over several decades. His practice consists mainly of real estate, corporate law, and business law."
      )}
    </Row>
  );
}
