import React from "react";
import house1 from "../../assets/house-1.jpg";
import house2 from "../../assets/house-2.jpg";
import house3 from "../../assets/house-3.jpg";
import house6 from "../../assets/house-6.jpg";
import colors from "../../constants/colors";
import logo from "../../assets/pt_logo_trans.png";
import QuoteForm from "./quote-form";

import Header from "../../header/Header";
import { CTAButton } from "../cta-button";

export default function Home(props: { isMobile: boolean }) {
  const renderCircle = (img: any, child: any) => {
    return (
      <div
        style={{
          marginLeft: 15,
          marginRight: 15,
          backgroundImage: "url(" + img + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: props.isMobile ? "80vw" : "20vw",
          width: props.isMobile ? "80vw" : "20vw",
          borderRadius: "50%",
          marginBottom: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: props.isMobile ? "80vw" : "20vw",
            width: props.isMobile ? "80vw" : "20vw",
            borderRadius: "50%",
            color: "white",
            fontWeight: 800,
            fontSize: 30,
            backgroundColor: colors.transparentRedDark,
            zIndex: 2,
          }}
        >
          {child}
        </div>
      </div>
    );
  };

  const renderTop = () => {
    if (props.isMobile) {
      return renderTopMobile();
    }
    return (
      <div
        style={{
          width: "100%",
          minHeight: "70vh",

          backgroundImage: "url(" + house2 + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            backgroundColor: colors.transparentRedExtraDark,
            minHeight: "70vh",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            paddingTop: 12,
          }}
        >
          <nav>
            <Header isMobile={props.isMobile} color={colors.offWhite} />
          </nav>
          <div
            style={{
              borderRadius: 3,
              padding: 20,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginRight: 30,
                borderRadius: 3,
                height: props.isMobile ? "40vw" : "24vw",
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                style={{
                  height: props.isMobile ? "40vw" : "24vw",
                }}
                className="logo"
                src={logo}
                alt=""
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginBottom: 50,
              }}
            >
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: 70,
                  marginBottom: 10,
                  textAlign: "center",
                  maxWidth: props.isMobile ? "85vw" : "75vw",
                  color: colors.offWhite,
                }}
              >
                Welcome to Preferred Title & Escrow
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTopMobile = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              borderRadius: 3,
              padding: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginRight: 30,
                borderRadius: 3,
                height: "40vw",
                alignSelf: "center",
                marginTop: 12,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                style={{
                  height: "40vw",
                }}
                className="logo"
                src={logo}
                alt=""
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                marginTop: 30,
              }}
            >
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: 40,
                  marginBottom: 10,
                  textAlign: "center",
                  maxWidth: "85vw",
                  color: colors.red,
                }}
              >
                Welcome to Preferred Title & Escrow
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTestimonialMobile = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 100,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              boxShadow: "2px 2px 12px 2px rgba(0,0,0,0.3)",
              backgroundColor: "white",
              borderRadius: 4,
              width: "100%",

              zIndex: 2,
            }}
          >
            <div
              style={{
                backgroundImage: "url(" + house6 + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 400,
                width: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: 400,
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  alignItems: "flex-end",

                  backgroundColor: colors.transparentRedLight,
                  padding: 12,
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 24,
                    marginLeft: 40,
                    color: "white",
                    textAlign: "right",

                    paddingBottom: 0,
                    marginBottom: -30,
                    maxWidth: "40%",
                  }}
                >
                  Proudly serving D.C. & Maryland since
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: 100,
                    fontWeight: "bold",
                    marginTop: 0,
                    paddingTop: 0,
                  }}
                >
                  1992
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTestimonial = () => {
    if (props.isMobile) {
      return renderTestimonialMobile();
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 200,
          paddingLeft: 100,
          paddingRight: 100,
        }}
      >
        {/* <p style={{ fontSize: 40, maxWidth: "70%" }}>
          "this should be a testimonial here about the best title services
          ever."
        </p> */}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              boxShadow: "2px 2px 12px 2px rgba(0,0,0,0.3)",
              backgroundColor: "white",
              borderRadius: 4,
              width: "60%",

              zIndex: 2,
            }}
          >
            <div
              style={{
                backgroundImage: "url(" + house6 + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 400,
                width: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: 400,
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  alignItems: "flex-end",

                  backgroundColor: colors.transparentRedLight,
                  padding: 12,
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 24,
                    marginLeft: 40,
                    color: "white",
                    textAlign: "right",

                    paddingBottom: 0,
                    marginBottom: -30,
                    maxWidth: "40%",
                  }}
                >
                  Proudly serving D.C. & Maryland since
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: 100,
                    fontWeight: "bold",
                    marginTop: 0,
                    paddingTop: 0,
                  }}
                >
                  1992
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              zIndex: 1,
              backgroundColor: colors.red,
              borderRadius: 3,
              marginLeft: -50,
              marginTop: -75,
              width: "40%",
              height: 450,
              padding: 75,
              color: colors.offWhite,
              fontSize: 24,
            }}
          >
            <p>
              Celebrating 31 years serving customers in Maryland and the
              District of Columbia
            </p>
            <p>
              more than <span style={{ fontWeight: "bold" }}>1000+</span>{" "}
              customers served
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderTestimonialOther = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: props.isMobile ? 0 : 100,
          paddingLeft: props.isMobile ? 0 : 100,
          paddingRight: props.isMobile ? 0 : 100,
        }}
      >
        <p
          style={
            props.isMobile
              ? {
                  marginTop: 50,
                  fontSize: 40,
                  width: "100%",
                  color: colors.red,
                  padding: 8,
                  paddingBottom: 0,
                  textAlign: "center",
                  fontWeight: "bold",
                }
              : {
                  fontSize: 40,
                  maxWidth: "100%",
                  textAlign: "center",
                  color: colors.red,
                  fontWeight: "bold",
                  marginBottom: 100,
                }
          }
        >
          Why use data scraping?
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: props.isMobile ? "column" : "row",
          }}
        >
          <div
            style={
              props.isMobile
                ? {
                    zIndex: 2,
                    backgroundColor: colors.red,
                    borderRadius: 3,

                    marginTop: 20,
                    width: "100%",

                    padding: 20,
                    color: "white",
                    fontSize: 20,
                  }
                : {
                    zIndex: 2,
                    backgroundColor: colors.red,
                    borderRadius: 3,

                    marginTop: -75,
                    width: "80%",
                    height: 450,
                    padding: 75,
                    color: "white",
                    fontSize: 20,
                    marginRight: -100,
                    marginLeft: 100,
                  }
            }
          >
            <ul>
              <li>
                <p>
                  Enrich your data sets with custom data points to secure a
                  competitive advantage
                </p>
              </li>

              <li>
                <p>Use analytics to spot trends in your industry</p>
              </li>
              <li>
                <p>Make better, data-driven decisions</p>
              </li>
              <li>
                <p>
                  Only with a good knowledge of the market, you can drive out
                  insights into what marketing decisions will get your company
                  ahead
                </p>
              </li>
            </ul>
          </div>
          <div
            style={{
              boxShadow: "2px 2px 12px 2px rgba(0,0,0,0.3)",
              backgroundColor: "white",
              borderRadius: 4,
              width: props.isMobile ? "100%" : "60%",
              marginLeft: props.isMobile ? 0 : "-10%",
              zIndex: 1,
              marginRight: 100,
            }}
          >
            <div
              style={{
                backgroundImage: "url(" + house6 + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: props.isMobile ? 250 : 400,
                width: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: props.isMobile ? 250 : 400,
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  alignItems: "flex-end",

                  backgroundColor: colors.transparentRedLight,
                  padding: 12,
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 24,
                    marginLeft: 40,
                    color: "white",
                    textAlign: "right",

                    paddingBottom: 0,
                    marginBottom: -30,
                    maxWidth: props.isMobile ? "80%" : "50%",
                  }}
                >
                  test string here
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: 100,
                    fontWeight: "bold",
                    marginTop: 0,
                    paddingTop: 0,
                  }}
                >
                  100
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderWordsSection = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 140,
          fontSize: 20,
        }}
        id={"our-services"}
      >
        <p
          style={{
            fontWeight: "bold",
            color: colors.red,
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Why choose Preferred Title & Escrow?
        </p>
        <p
          style={{
            maxWidth: props.isMobile ? "95%" : "80%",
            textAlign: props.isMobile ? "center" : "center",
          }}
        >
          When you purchase residential real estate, you as the buyer have the
          right to choose where you settle. You can designate your choice of
          settlement company right on your contract. Preferred Title & Escrow,
          Inc. invites you to settle with us. Here’s why:
        </p>
        <ul
          style={{
            maxWidth: props.isMobile ? "95%" : "70%",
            marginTop: 20,
          }}
        >
          <li style={{ marginTop: 8 }}>
            Our fee includes: conducting the settlement, title examination and
            preparation of the Deed, Note and Deed of Trust if required. Our
            fees are very competitive and are often lower than attorneys.
          </li>
          <li style={{ marginTop: 8 }}>
            Friendly, knowledgeable staff available to answer your questions
            both before and after settlement.
          </li>
          <li style={{ marginTop: 8 }}>
            All settlements conducted by experienced professionals who will
            thoroughly explain all documents to you at settlement
          </li>
          <li style={{ marginTop: 8 }}>
            Our office utilizes the latest computerized settlement systems
          </li>
          <li style={{ marginTop: 8 }}>
            Our staff works closely with your real estate agent and your lender
            to assure a smooth, trouble free settlement
          </li>
          <li style={{ marginTop: 8 }}>
            We are able to handle your Residential and Commercial needs
          </li>
          <li style={{ marginTop: 8 }}>
            Settlements for properties throughout Maryland and the District of
            Columbia
          </li>
          <li style={{ marginTop: 8 }}>Rush settlements available</li>
          <li style={{ marginTop: 8 }}>Free parking</li>
        </ul>
      </div>
    );
  };

  const renderCirclesRow = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: props.isMobile ? "column" : "row",

          justifyContent: "center",
          padding: props.isMobile ? 0 : 50,
        }}
      >
        {renderCircle(
          house2,
          <a
            href="https://fntic.com/whatistitle.aspx"
            target="_blank"
            className="circle"
          >
            <div
              className={
                props.isMobile ? "gradientCircleMobile" : "gradientCircle"
              }
            >
              <p style={{ textAlign: "center" }}>What is Title Insurance?</p>
            </div>
          </a>
        )}
        {renderCircle(
          house3,
          <a
            href="https://ratecalculator.fnf.com/"
            target="_blank"
            className="circle"
          >
            <div
              className={
                props.isMobile ? "gradientCircleMobile" : "gradientCircle"
              }
            >
              <p style={{ textAlign: "center" }}>Title Rate Estimator</p>
            </div>
          </a>
        )}
        {renderCircle(
          house1,
          <a
            href="https://www.princegeorgescountymd.gov/2964/First-Time-Home-Buyer-Assistance-Program"
            target="_blank"
            className="circle"
          >
            <div
              className={
                props.isMobile ? "gradientCircleMobile" : "gradientCircle"
              }
            >
              <p style={{ textAlign: "center" }}>My Home Approved</p>
            </div>
          </a>
        )}
      </div>
    );
  };

  const renderWorkedWithMobile = () => {
    return (
      <div
        style={{
          marginTop: 80,
          padding: 40,
          backgroundColor: colors.red,
          width: "110%",
          marginLeft: "-5%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <p
            style={{
              marginTop: 40,
              marginBottom: 12,
              fontWeight: "bold",
              color: colors.offWhite,
              textAlign: "center",
            }}
          >
            The owners of Preferred Title & Escrow, Inc. have over 45 years in
            the title business.
          </p>

          <p
            style={{
              marginBottom: 12,
              fontWeight: "bold",
              color: colors.offWhite,
              textAlign: "center",
            }}
          >
            We know how to work effectively with Realtors and lenders so that
            the settlements go smoothly. We offer friendlier, more personalized
            service and we take the time to make the customer completely
            comfortable prior to and during settlement.
          </p>
        </div>

        <a
          href="#quote"
          style={{ textDecoration: "none", marginTop: 40, marginBottom: 30 }}
        >
          <CTAButton text={`Request a quote`} isMobile={props.isMobile} />
        </a>
      </div>
    );
  };

  const renderWorkedWith = () => {
    if (props.isMobile) {
      return renderWorkedWithMobile();
    }

    // return (
    //   <MiddleSection
    //     isMobile={false}
    //     title={
    //       "The owners of Preferred Title & Escrow, Inc. have over 45 years in the title business."
    //     }
    //     text={
    //       "We know how to work effectively with Realtors and lenders so that the settlements go smoothly. We offer friendlier, more personalized service and we take the time to make the customer completely comfortable prior to and during settlement."
    //     }
    //     image={house1}
    //   />
    // );

    return (
      <div
        style={{
          marginTop: 80,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* <div
          style={{
            borderTopColor: colors.yellow,
            borderTopWidth: 5,
            width: "70%",
            borderTopStyle: "solid",
          }}
        ></div> */}
        <div style={{ maxWidth: 1100 }}>
          <div
            style={{
              padding: 30,
              backgroundColor: colors.white,

              width: "100%",

              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              boxShadow: "2px 2px 12px 2px rgba(0,0,0,0.3)",
              borderRadius: 4,
            }}
          >
            <div
              style={{ paddingLeft: 80, paddingRight: 80, paddingBottom: 30 }}
            >
              <p
                style={{
                  textAlign: "center",
                  alignSelf: "flex-start",
                  marginTop: 40,
                  fontWeight: "bold",
                  color: colors.red,

                  fontSize: 24,
                  marginBottom: 20,
                }}
              >
                The owners of Preferred Title & Escrow, Inc. have over <br />
                45 years in the title business.
              </p>

              <p
                style={{
                  textAlign: "center",
                  alignSelf: "flex-start",
                  // fontWeight: "bold",
                  marginTop: 40,

                  color: colors.red,
                  fontSize: 20,
                }}
              >
                We know how to work effectively with Realtors and lenders so
                that the settlements go smoothly. We offer friendlier, more
                personalized service and we take the time to make the customer
                completely comfortable prior to and during settlement.
              </p>
            </div>

            <a
              href="#quote"
              style={{
                textDecoration: "none",
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              <div
                style={{
                  width: 240,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderColor: colors.yellow,
                  borderWidth: 2,
                  borderRadius: 8,
                  backgroundColor: colors.yellow,
                  borderStyle: "solid",
                }}
              >
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontWeight: 800,
                    letterSpacing: 1.02,
                    fontSize: 20,
                    color: colors.red,
                  }}
                >
                  <CTAButton
                    text={`Request a quote`}
                    isMobile={props.isMobile}
                  />
                </p>
              </div>
            </a>
          </div>
        </div>

        {/* <div
          style={{
            marginTop: 50,
            borderTopColor: colors.yellow,
            borderTopWidth: 5,
            borderTopStyle: "solid",
            width: "70%",
          }}
        ></div> */}
      </div>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {renderTop()}

      {/* {renderTestimonialOther()} */}
      {renderWorkedWith()}
      {renderTestimonial()}

      {renderWordsSection()}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 140,
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            color: colors.red,
            fontSize: 24,
            textAlign: props.isMobile ? "center" : "left",
          }}
        >
          Learn more about Title Services:
        </p>
      </div>
      {renderCirclesRow()}

      <QuoteForm isMobile={props.isMobile} />
    </div>
  );
}
