export default {
  // red: "rgb(140, 26, 11)", //8c1a0b
  red: "rgb(96, 32, 48)", // 602030
  transparentRed: "rgba(96, 32, 48, 0.4)",
  transparentRedDark: "rgba(96, 32, 48, 0.5)",
  transparentRedExtraDark: "rgba(96, 32, 48, 0.7)",
  transparentRedLight: "rgba(96, 32, 48, 0.3)",
  offWhite: "#F9F2EE",
  white: "#fff",
  yellow: "#F0B749",
};
