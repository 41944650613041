import React from "react";
import ReactDOM from "react-dom";
import "./setup/index.css";

import App from "./routing/App";
import * as serviceWorker from "./setup/serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
