import React from "react";
import colors from "../../constants/colors";
import { ContactUsForm } from "../home-page/quote-form";

export default function Contact(props: { isMobile: boolean }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ paddingLeft: 50, marginTop: 30 }}>
          <h2
            style={{
              fontWeight: "bold",
              fontSize: 70,
              marginBottom: 40,

              color: colors.red,
            }}
          >
            Contact Us
          </h2>
        </div>
      </div>

      <ContactUsForm isMobile={props.isMobile} />
    </div>
  );
}
