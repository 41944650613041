import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../setup/App.scss";
import SimpleReactLightbox from "simple-react-lightbox";
import colors from "../constants/colors";
import Home from "../pages/home-page/home";
import Header from "../header/Header";
import Contact from "../pages/contact-us/contact";
import Footer from "@tara-wilson/mcc-web-components/src/web-components/footer";
import MobileHeaderMenu from "@tara-wilson/mcc-web-components/src/web-components/mobile-header-menu";
import OurTeam from "../pages/services-pages/our-team";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import logo from "../assets/pt_logo_trans.png";

// actual changes
export default function App() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  return (
    <SimpleReactLightbox>
      <div
        style={{
          width: "100vw",
          height: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colors.yellow,
        }}
      >
        <div
          style={{
            fontFamily: "Playfair Display",

            color: colors.red,
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          NEW: Apply{" "}
          <a
            href="https://www.greenbeltmd.gov/government/city-administration/city-manager-s-office/american-rescue-plan-act-arpa/greenbelt-homes-inc-first-time-homebuyers-mortgage-assistance-program"
            target="_blank"
            style={{
              fontWeight: 900,
              textDecorationLine: "underline",
              textDecorationColor: colors.red,
            }}
          >
            here
          </a>{" "}
          for a Greenbelt Homes Inc. Grant
        </div>
      </div>
      <Router>
        {isMobile ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: 50,
              height: 50,
              zIndex: 99,
            }}
          >
            <MobileHeaderMenu
              burgerColor={colors.red}
              content={
                <div style={{ padding: 20, zIndex: 99 }}>
                  <img src={logo} style={{ width: 80, marginBottom: 20 }} />
                  <Link to="/">
                    <p style={{ color: "#fff" }}>Preferred Title & Escrow</p>
                  </Link>

                  <a href="/#our-services" style={{ color: "#fff" }}>
                    <p>Our Services</p>
                  </a>

                  <a href="/our-team" style={{ color: "#fff" }}>
                    <p>Our Team</p>
                  </a>

                  <a
                    href="https://preferredtitle.titlecapture.com/login"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    <p>Get a Title Quote</p>
                  </a>

                  <Link to="/contact">
                    <p style={{ color: "#fff" }}>Contact Us</p>
                  </Link>
                </div>
              }
            />
          </div>
        ) : (
          <div />
        )}
        <div
          style={
            isMobile
              ? {
                  backgroundColor: colors.offWhite,
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 0,
                  marginBottom: 0,
                  minHeight: "100vh",
                  borderRadius: 0,
                  boxShadow: "none",
                  padding: 20,
                  width: "100%",
                  minWidth: "100vw",
                  maxWidth: "100vw",
                  overflow: "hidden",
                }
              : {
                  backgroundColor: colors.offWhite,
                  marginLeft: "5rem",
                  marginRight: "5rem",
                  marginTop: "3rem",
                  marginBottom: 0,
                  minHeight: "100vh",
                  borderRadius: 8,
                  boxShadow: "2px 2px 12px 2px rgba(0,0,0,0.3)",
                  padding: 20,
                }
          }
        >
          <Switch>
            <Route path="/contact">
              <nav>
                <Header isMobile={isMobile} color={colors.red} />
              </nav>
              <Contact isMobile={isMobile} />
            </Route>

            <Route path="/our-team">
              <OurTeam isMobile={isMobile} />
            </Route>

            <Route path="/">
              <Home isMobile={isMobile} />
            </Route>
          </Switch>

          <Footer
            titleColor={colors.red}
            subColor={"rgba(0, 0, 0, 0.7)"}
            subtitle={
              <p style={{ textAlign: "center" }}>
                7701 Greenbelt Rd Suite #513 <br />
                Greenbelt, MD 20770 <br />
                Tel: (301)-513-9090 <br />
                <a href="mailto:settle@preferredtitle.us">
                  settle@preferredtitle.us
                </a>
                <br />
              </p>
            }
            company={"Preferred Title & Escrow"}
            logo={require("../assets/pt_logo_trans.png")}
            titles={["Links"]}
            width={isMobile ? "95vw" : "85vw"}
            links={[
              [
                {
                  title: "Fidelity National Title Insurance",
                  link: "https://www.fntic.com/",
                },

                { title: "Contact Us", link: "/contact" },
                { title: "Our Team", link: "/our-team" },
                // { title: "Competitor Price Tracking", link: "/price-tracking" },
                // { title: "Academic Research", link: "/academic-research" },
              ],
              // [
              //   // { title: "Contact", link: "/contact" },
              //   // { title: "Case Studies", link: "/case-studies" },
              // ],
            ]}
          />
        </div>
      </Router>
    </SimpleReactLightbox>
  );
}
