import React from "react";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Header(props: { isMobile: boolean; color: string }) {
  let location = useLocation();

  const isNotHome =
    location.pathname.toLowerCase().includes("/contact") ||
    location.pathname.toLowerCase().includes("/price") ||
    location.pathname.toLowerCase().includes("/our-team");

  return !props.isMobile ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: 20,
        zIndex: 99,
      }}
    >
      <div style={{ flex: 1 }} />
      {isNotHome ? (
        <Link to="/">
          <p style={{ marginRight: 14, fontSize: 20, color: props.color }}>
            Home
          </p>
        </Link>
      ) : (
        <div />
      )}
      <a
        href="/#our-services"
        style={{ marginRight: 14, fontSize: 20, color: props.color }}
      >
        Our Services
      </a>

      <a
        href="/our-team"
        style={{ marginRight: 14, fontSize: 20, color: props.color }}
      >
        Our Team
      </a>

      <a
        href="https://preferredtitle.titlecapture.com/login"
        target="_blank"
        style={{ marginRight: 14, fontSize: 20, color: props.color }}
      >
        <p>Get a Title Quote</p>
      </a>

      <Link to="/contact">
        <p style={{ marginRight: 14, fontSize: 20, color: props.color }}>
          Contact Us
        </p>
      </Link>
    </div>
  ) : (
    <div />
  );
}

export default Header;
