import React from "react";
import colors from "../../constants/colors";
import Header from "../../header/Header";

export default function TopSection(props: {
  isMobile: boolean;
  title: string;
  text: string;
  image: any;
  altText?: string;
}) {
  return (
    <div
      style={{
        width: "100%",
        minHeight: props.isMobile ? "auto" : "65vh",
        backgroundImage: "url(" + props.image + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: 8,
      }}
    >
      <div
        style={{
          backgroundColor: colors.transparentRedExtraDark,
          minHeight: props.isMobile ? "auto" : "65vh",
          borderRadius: 8,
          paddingTop: 12,
        }}
      >
        <nav style={{}}>
          <Header isMobile={props.isMobile} color={colors.white} />
        </nav>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={
              props.isMobile
                ? { padding: 30 }
                : { paddingLeft: 50, marginTop: 30 }
            }
          >
            <h2
              style={{
                fontWeight: "bold",
                fontSize: props.isMobile ? 40 : 70,
                marginBottom: props.isMobile ? 0 : 40,
                color: colors.offWhite,
              }}
            >
              {props.title}
            </h2>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: props.isMobile ? 30 : 60,
            color: colors.offWhite,
            paddingRight: props.isMobile ? 30 : 0,
          }}
        >
          <p
            style={{
              maxWidth: props.isMobile ? "95vw" : 800,
              marginBottom: props.isMobile ? 60 : 0,
              fontSize: props.isMobile ? 14 : 20,
            }}
          >
            {props.text}
          </p>
        </div>
      </div>
    </div>
  );
}
