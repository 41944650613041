import React from "react";
import colors from "../../constants/colors";
import house8 from "../../assets/house-8.jpg";
import { CTAButton } from "../cta-button";

export default function QuoteForm(props: { isMobile: boolean }) {
  return (
    <div
      id="quote"
      style={{
        alignSelf: "center",
        marginTop: props.isMobile ? 70 : 150,
        backgroundImage: "url(" + house8 + ")",
        backgroundRepeat: "no-repeat",

        backgroundSize: "cover",
        width: props.isMobile ? "100vw" : "70vw",
        height: 750,
        borderRadius: 7,

        marginBottom: props.isMobile ? 150 : 100,
        zIndex: 1,
        display: "flex",
        flexDirection: props.isMobile ? "column" : "row",
      }}
    >
      <div
        style={{
          borderRadius: 7,
          display: "flex",
          flexDirection: props.isMobile ? "column" : "row",
          flex: 1,
          height: 750,
          padding: props.isMobile ? 0 : 20,
          backgroundColor: colors.transparentRedExtraDark,
          zIndex: 2,
        }}
      >
        <p
          style={{
            padding: props.isMobile ? 20 : 0,
            fontSize: 40,
            maxWidth: props.isMobile ? "100%" : "50%",
            zIndex: 3,
            marginTop: 10,
            marginLeft: 20,
            color: "white",
            fontWeight: "bold",
            marginBottom: props.isMobile ? 0 : 20,
          }}
        >
          Get your free quote today.
        </p>

        <div
          style={
            props.isMobile
              ? {
                  marginTop: 12,

                  backgroundColor: "white",

                  width: "100%",
                  height: 450, // height: 760,
                  zIndex: 2,

                  color: "black",

                  // tara here
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {
                  marginTop: 80,
                  marginRight: 40,
                  marginLeft: -120,
                  marginBottom: -60,
                  boxShadow: "2px 2px 12px 2px rgba(0,0,0,0.3)",
                  backgroundColor: "white",
                  borderRadius: 4,
                  width: "60%",
                  height: 450, // height: 760,
                  zIndex: 2,

                  color: "black",
                  // tara here
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
          }
        >
          {/* <p>Quote form coming soon...</p> */}
          <p
            style={{
              color: colors.red,
              marginLeft: 80,
              marginRight: 80,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            Send us an email with the details of your request and we'll get back
            to you with your free quote right away.
          </p>
          <a
            href="mailto:settle@preferredtitle.us"
            style={{ textDecoration: "none", marginTop: 40, marginBottom: 30 }}
          >
            <CTAButton text={`Contact Us`} isMobile={props.isMobile} />
          </a>
          {/* <iframe
            src="https://cdn.forms-content.sg-form.com/34cb0b89-91a6-11eb-8d6f-2e266d0f836a"
            style={{ width: "100%", height: 750, borderWidth: 0 }}
          /> */}
        </div>
      </div>
    </div>
  );
}

export function ContactUsForm(props: { isMobile: boolean }) {
  return (
    <div
      id="quote"
      style={{
        alignSelf: "center",

        backgroundImage: "url(" + house8 + ")",
        backgroundRepeat: "no-repeat",

        backgroundSize: "cover",
        width: props.isMobile ? "100vw" : "70vw",
        height: 550,
        borderRadius: 7,

        marginBottom: props.isMobile ? 150 : 100,
        zIndex: 1,
        display: "flex",
        flexDirection: props.isMobile ? "column" : "row",
      }}
    >
      <div
        style={{
          borderRadius: 7,
          display: "flex",
          flexDirection: props.isMobile ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          height: 550,
          padding: props.isMobile ? 0 : 20,
          backgroundColor: colors.transparentRedExtraDark,
          zIndex: 2,
        }}
      >
        <div
          style={
            props.isMobile
              ? {
                  marginTop: 12,

                  backgroundColor: "white",

                  width: "100%",
                  height: 560,
                  zIndex: 2,

                  color: "black",
                  // tara here
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {
                  width: "60%",
                  marginTop: 80,

                  marginBottom: -60,
                  boxShadow: "2px 2px 12px 2px rgba(0,0,0,0.3)",
                  backgroundColor: "white",
                  borderRadius: 4,

                  height: 560,
                  zIndex: 2,

                  color: "black",
                  // tara here
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }
          }
        >
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.red,
              fontSize: 24,
            }}
          >
            Our Location:
          </p>
          <p style={{ textAlign: "center" }}>
            7701 Greenbelt Rd Suite #513 <br />
            Greenbelt, MD 20770 <br />
          </p>

          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.red,
              fontSize: 24,
            }}
          >
            Give us a call:
          </p>
          <p style={{ textAlign: "center" }}>
            Tel: (301)-513-9090 <br />
          </p>

          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: colors.red,
              fontSize: 24,
            }}
          >
            Email us:
          </p>
          <p style={{ textAlign: "center" }}>
            <a href="mailto:settle@preferredtitle.us">
              settle@preferredtitle.us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
